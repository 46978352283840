export const useMinHeightStyles = (props: any) => {
  if (props.minHeight) {
    return {
      style: {
        '--min-h': props.minHeight
      },
      className: 'min-h-[var(--min-h)]'
    };
  }

  return {};
};
