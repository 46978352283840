import React from 'react';
import dynamic from 'adapter/next/dynamic';
import { useInView } from 'react-intersection-observer';
import { SectionHeader, type SectionHeaderProps } from '@konsus/lego/src/components/SectionHeader';
import {
  KeyFeaturesItem,
  type KeyFeaturesItemProps
} from '@konsus/lego/src/blocks/KeyFeatures/KeyFeaturesItem';
import { Box, cn } from '@superside/ui';
import { PageSection } from '@konsus/lego/src/components';

const ArticleFooterSectionImages = dynamic(
  () => import(/* webpackChunkName: "ArticleFooterSectionImages" */ './ArticleFooterSectionImages')
);

export interface ArticleFooterSectionProps {
  items: Array<KeyFeaturesItemProps>;
  header: SectionHeaderProps;
  ctaButton?: {
    ctaText: string;
    ctaUrl: string;
  };
  disable?: boolean;
}

export const ArticleFooterSection: React.FC<ArticleFooterSectionProps> = (props) => {
  const { items, header, ctaButton } = props;
  const [ref, isVisible] = useInView({ rootMargin: '500px 0px', triggerOnce: true });

  return (
    <Box ref={ref}>
      <PageSection
        unlimited
        paddingBottom='none'
        className={cn(
          '1280:from[theme(colors.light)_90px] 1280:to-[transparent_90px] 1280:pt-[90px] dark bg-gradient-to-b from-[theme(colors.light)_66px] via-[transparent_66px] pt-16',
          'z-[2] flex items-center justify-center'
        )}
      >
        <Box className='768:px-6 768:pt-20 1280:max-w-[1062px] 1280:pt-40 1440:pt-32 1440:pb-2 1650:max-w-aurora relative px-4 pt-10'>
          {isVisible ? <ArticleFooterSectionImages /> : null}

          <SectionHeader
            className='[&_h2]:768:text-4xl [&_h2]:1440:text-4.5xl z-[2] [&_h2]:text-3xl'
            ctaButton={ctaButton && { ctaText: ctaButton.ctaText, ctaLink: ctaButton.ctaUrl }}
            {...header}
          />

          {items ? (
            <div className='768:grid-cols-2 1024:grid-cols-4 1024:gap-5 grid grid-cols-1 gap-4'>
              {items.map((item) => (
                <KeyFeaturesItem
                  className='items-start after:absolute after:bottom-0 after:left-0 after:h-1 after:w-full after:rounded-lg after:bg-blue-200/30 [&_img]:max-w-[96px_!important] [&_img]:object-contain'
                  key={item._key}
                  {...item}
                />
              ))}
            </div>
          ) : null}
        </Box>
      </PageSection>
    </Box>
  );
};
