import React, { useMemo } from 'react';
import { cn } from '@superside/ui';
import { Box, type BoxProps } from '../Box';
import { composeStyles, isDark } from '../../utils';
import colors from '../../theme/colors';
import { styling } from '../BreakpointShowBox/breakpointShowStyles';
import { useBackgroundStyles, useMinHeightStyles, useZIndexStyles } from '../../styles';
import theme from '../../theme/theme';
import styles from './PageSectionWrapper.module.css';

// @ts-ignore
const useStyles = composeStyles(useMinHeightStyles, useZIndexStyles, useBackgroundStyles);

export const PageSectionWrapper: React.FC<
  BoxProps & {
    backgroundSize?: string;
    backgroundPosition?: string;
    overflowHidden?: any;
    style?: any;
    className?: string;
    children?: any;
  }
> = ({ backgroundSize, backgroundPosition, ...props }) => {
  const dark = useMemo(
    () => isDark(colors[props.background] || props.background),
    [props.background]
  );

  const { style, className } = useStyles(
    {
      ...props
    },
    theme
  );

  return (
    <Box
      {...props}
      style={{
        // @ts-ignore
        '--bg-size': backgroundSize || 'cover',
        '--bg-position': backgroundPosition || 'center',
        ...style,
        ...props.style
      }}
      className={cn(
        'relative bg-no-repeat',
        styles.pageSectionWrapper,
        className,
        props.className,
        dark ? 'dark' : 'light',
        styling(props),
        props.overflowHidden && 'overflow-hidden'
      )}
    >
      {props.children}
    </Box>
  );
};
