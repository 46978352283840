import React, { useMemo } from 'react';
import { RelativeBox, type BoxProps } from '../Box';
import { type HeadingProps } from '../Heading';
import { PageSectionHeading as Heading } from './PageSectionHeading';
import { PageSectionWrapper as Wrapper } from './PageSectionWrapper';
import {
  PageSectionContent as Content,
  pageSectionHorizontalPaddingSize,
  pageSectionVerticalMarginSize,
  pageSectionVerticalPaddingSize
} from './PageSectionContent';
import { SectionIdElement } from './SectionIdElement';

const colors = {
  'transparent-on-dark': { color: 'transparent', dark: true },
  'transparent-on-light': { color: 'transparent', dark: false }
};

const getEdgeSize = (value, positiveValue, negativeValue = 'none') =>
  typeof value === 'boolean' ? (value ? positiveValue : negativeValue) : value;

export interface PageSectionProps extends BoxProps {
  sectionId?: string;
  hashId?: string;
  sectionRef?: any;
  sectionFlex?: BoxProps['flex'];
  legend?: JSX.Element | string;
  heading?: JSX.Element | string;
  headingLevel?: HeadingProps['level'];
  headingMaxWidth?: string;
  headingWrapperMaxWidth?: string;
  unlimited?: boolean;
  maxWidth?: string;
  hasStarryBackground?: boolean;
  hasPlanetsBackground?: boolean;
  hasSpaceSurfaceImage?: boolean;
  paddingTop?: string | boolean;
  paddingBottom?: string | boolean;
  paddingHorizontal?: object | string | boolean;
  marginTop?: string | boolean;
  marginBottom?: string | boolean;
  backgroundImage?: string;
  mobileBackgroundImage?: string;
  backgroundVideo?: string;
  mobileBackgroundVideo?: string;
  style?;
  className?: string;
  overflowHidden?: boolean;
  title?: string;
  children?: React.ReactNode;
}

export const PageSection: React.FC<PageSectionProps> = (props) => {
  const {
    unlimited,
    maxWidth,
    hasStarryBackground = false,
    sectionFlex = false,
    sectionId,
    sectionRef,
    legend,
    heading,
    headingLevel = '2',
    headingMaxWidth,
    headingWrapperMaxWidth = maxWidth,
    paddingTop,
    paddingBottom,
    paddingHorizontal = true,
    marginTop,
    marginBottom,
    gap,
    wrap,
    align,
    justify,
    direction,
    responsive,
    mobileBackgroundImage,
    backgroundImage,
    backgroundVideo,
    mobileBackgroundVideo,
    hashId,
    background = hasStarryBackground ? 'space' : 'white',
    flex = { grow: 0, shrink: 0 },
    ...rest
  } = props;

  const finalHPad = useMemo(() => {
    let pad = getEdgeSize(paddingHorizontal, pageSectionHorizontalPaddingSize);

    if (typeof pad === 'string') {
      pad = { left: pad, right: pad };
    }

    return pad;
  }, [paddingHorizontal]);

  const finalVPad = useMemo(
    () => ({
      top: getEdgeSize(paddingTop, pageSectionVerticalPaddingSize),
      bottom: getEdgeSize(paddingBottom, pageSectionVerticalPaddingSize)
    }),
    [paddingTop, paddingBottom]
  );

  const finalMargin = useMemo(
    () => ({
      top: getEdgeSize(marginTop, pageSectionVerticalMarginSize),
      bottom: getEdgeSize(marginBottom, pageSectionVerticalMarginSize)
    }),
    [marginTop, marginBottom]
  );

  return (
    <Wrapper
      tag='section'
      flex={sectionFlex}
      fill='horizontal'
      {...rest}
      id={sectionId}
      ref={sectionRef}
      background={colors[background as string] || background}
      margin={finalMargin}
      pad={finalVPad}
      backgroundImage={backgroundImage}
      mobileBackgroundImage={mobileBackgroundImage}
      backgroundVideo={backgroundVideo}
      mobileBackgroundVideo={mobileBackgroundVideo}
    >
      {typeof hashId !== 'undefined' ? <SectionIdElement id={hashId} /> : null}

      {typeof heading === 'string' || legend ? (
        <Heading
          level={headingLevel}
          legend={legend}
          maxWidth={headingMaxWidth}
          wrapperMaxWidth={headingWrapperMaxWidth}
        >
          {heading}
        </Heading>
      ) : (
        heading
      )}

      <Content
        zIndex={hasStarryBackground ? 1 : undefined}
        unlimited={unlimited}
        maxWidth={maxWidth}
        flex={flex}
      >
        <RelativeBox
          flex={flex}
          wrap={wrap}
          direction={direction}
          justify={justify}
          responsive={responsive}
          align={align}
          pad={finalHPad}
          gap={gap}
          className='wrapper-with-h-paddings'
        >
          {props.children}
        </RelativeBox>
      </Content>
    </Wrapper>
  );
};

export const PageSectionWrapper = Wrapper;
export const PageSectionHeading = Heading;
export const PageSectionContent = Content;
export const PageSectionIdElement = SectionIdElement;

export default PageSection;
