import React from 'react';
import { Text } from '../Text';
import { Heading, type HeadingProps } from '../Heading';
import {
  PageSectionContent,
  pageSectionHeadingMarginBottomSize,
  pageSectionHorizontalPaddingSize
} from './PageSectionContent';

export const PageSectionHeading: React.FC<
  HeadingProps & {
    legend?: JSX.Element | string;
    maxWidth?: string;
    wrapperMaxWidth?: string;
  }
> = ({ children, legend, wrapperMaxWidth, hasCloseBtn, ...props }) => {
  const content =
    typeof children === 'string' ? (
      <Heading
        {...props}
        margin={
          props.margin || {
            bottom: pageSectionHeadingMarginBottomSize,
            horizontal: pageSectionHorizontalPaddingSize,
            right: hasCloseBtn ? 'xlarge' : 'none'
          }
        }
        level={props.level || 2}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    ) : (
      children
    );

  return (
    <PageSectionContent maxWidth={wrapperMaxWidth}>
      {typeof legend === 'string' ? (
        <Text
          margin={{
            bottom: 'small',
            horizontal: pageSectionHorizontalPaddingSize
          }}
          dangerouslySetInnerHTML={{ __html: legend.toUpperCase() }}
        />
      ) : (
        legend
      )}
      {content}
    </PageSectionContent>
  );
};
