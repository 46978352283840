import React from 'react';

export const SectionIdElement = (props) => {
  return (
    <div
      className={'1024:[body:not(.without-header)_&]:-top-header absolute left-0 top-0'}
      {...props}
    />
  );
};
