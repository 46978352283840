export const useZIndexStyles = (props) => {
  if (props.zIndex) {
    return {
      style: {
        '--z': props.zIndex
      },
      className: 'z-[var(--z)]'
    };
  }

  return {};
};
