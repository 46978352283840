import React, { useCallback, useMemo, useState } from 'react';
import { CaretLeft, Check } from 'phosphor-react';
import SubscribeForm from '@konsus/lego/src/blocks/SubscribeForm';
import { type TrackingFormData, useSubscribeForm } from '@konsus/lego/src/utils/useSubscribeForm';
import { ExternalContentWrapper } from '@konsus/superside-kit';
import useSanity from '@konsus/sanity-client';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import { Box, cn, Button, Heading } from '@superside/ui';
import BookCallCustomForm from '../BookCallCustomForm';

const FooterForm = ({
  formId,
  trackingEvents
}: {
  formId: string;
  trackingEvents?: TrackingFormData;
}) => {
  const [formFooterComponent] = useSanity(`*[_type == 'footerFormContent'][0]`);
  const [subscribeFormSubmitted, setSubscribeFormSubmitted] = useState(false);
  const [bookCallForm, setBookCallForm] = useState(true);
  const [withSubscribe, setWithSubscribe] = useState(true);

  const toggleSubscribeIntention = useCallback(() => {
    setWithSubscribe(!withSubscribe);
  }, [withSubscribe]);

  const onSuccess = useCallback(() => {
    setSubscribeFormSubmitted(true);
  }, []);

  const copy = useMemo(() => {
    return bookCallForm
      ? formFooterComponent.data?.getStartedFormCopy
      : formFooterComponent.data?.subscribeFormCopy;
  }, [bookCallForm, formFooterComponent]);

  const { subscribeFormHandler } = useSubscribeForm({ onSuccess, formId, trackingEvents });

  return (
    <Box
      className={
        'border-b-grey-200 768:pb-15 768:mt-3 1280:border-b-0 1280:mt-0 mt-12  mb-2 w-full flex-auto border-b-2 pb-10'
      }
    >
      <Box className='768:max-w-[534px]'>
        <Heading type='h5' className='1024:mb-4 1440:text-4.5xl mb-3'>
          {copy?.headerText}
        </Heading>
        {copy?.subHeaderRichText ? (
          <ExternalContentWrapper className={'[&_p]:first:mt-0 [&_p]:last:mb-0'}>
            <SanityBlockComponent blocks={copy?.subHeaderRichText} />
          </ExternalContentWrapper>
        ) : null}
      </Box>

      <Box className={'1280:mt-12 mt-6'}>
        {bookCallForm ? (
          <Box>
            <BookCallCustomForm
              onSubmit={withSubscribe ? subscribeFormHandler : undefined}
              withSubscribe={withSubscribe}
              trackingEvents={trackingEvents}
            />
            {subscribeFormSubmitted ? null : (
              <Box className='mt-4 flex-row items-center'>
                <Box className={'mr-3 items-center'} onClick={toggleSubscribeIntention}>
                  <div
                    className={cn(
                      'border-grey-300 flex cursor-pointer rounded border-2 bg-transparent p-[2px]',
                      withSubscribe && 'bg-dark border-transparent'
                    )}
                  >
                    <Check size={10} weight='bold' className='text-light' />
                  </div>
                </Box>
                <Box className='flex-auto flex-row flex-wrap'>
                  {/* font-weight: 400;
  margin-right: 4px; */}
                  <Button
                    variant='secondaryGhost'
                    className='mr-1 font-normal'
                    onClick={toggleSubscribeIntention}
                  >
                    <span>I also want to subscribe to</span>
                  </Button>
                  <Button
                    variant='secondaryGhost'
                    className='underline hover:no-underline'
                    onClick={() => setBookCallForm(false)}
                  >
                    {' '}
                    Superside&apos;s newsletter
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            {subscribeFormSubmitted ? (
              <Box className='flex-row items-center justify-between border border-green-700 px-5 py-4'>
                <span className='font-bold text-green-700'>
                  {copy?.thankYouMessage || 'Thank you!'}
                </span>
                <div className='flex rounded-full bg-green-700 p-1'>
                  <Check className='text-light' weight='bold' />
                </div>
              </Box>
            ) : (
              <SubscribeForm
                onSubmit={subscribeFormHandler}
                background='#FAFAFA'
                buttonLabel='Subscribe'
                legacy={false}
                small
              />
            )}
            <Button
              variant='secondaryGhost'
              className='mt-4 flex items-center self-start'
              onClick={() => setBookCallForm(true)}
            >
              <CaretLeft weight='bold' className='inline' />
              <span className='ml-3 underline hover:no-underline'>Book a call</span>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FooterForm;
