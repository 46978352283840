export const DEFAULT_HEADING =
  'Superside is a revolutionary way for businesses to get good design done at scale.';

export const DEFAULT_TEXT =
  'Trusted by 450+ ambitious companies, Superside makes design hassle-free for marketing and creative teams. By combining the top 1% of creative talent from around the world with purpose-built technology and the rigor of design ops, Superside helps ambitious brands grow faster. Since inception, Superside has been a fully remote company, with almost 600 team members working across 57 countries and 13 timezones.';

export const BOTTOM_LINKS = [
  { linkText: 'Privacy Policy', href: 'https://superside.com/privacy' },
  { linkText: 'Terms of Use', href: 'https://superside.com/terms-of-use' },
  { linkText: 'Status page', href: 'https://status.superside.com' }
];

export const COPYRIGHT_TEXT = `© ${new Date().getFullYear()} Superside. All rights reserved.`;
