import React from 'react';
import { LegacyPageSection } from '../../components/LegacyPageSection';
import { BannerContent } from './BannerContent';
import { type BannerProps } from './types';

export const Banner: React.FC<BannerProps> = (props) => {
  const {
    copy,
    variant = 'withButton',
    button,
    ctaButton,
    form,
    backgroundColor = '#0F2133',
    desktopBgImage,
    mobileBgImage = desktopBgImage,
    backgroundVariant = 'fullWidthBg',
    isLazy = true,
    boxShadow = false,
    thankYouCopy,
    thankYouMobileImage,
    smallBoxShadow,
    secondaryButton,
    successContent,
    desktopOverflowImage,
    mobileOverflowImage,
    imageOverflowType,
    onlyCompanyEmail,
    richTextProgressiveForm,
    questions,
    formSource,
    predefinedForm,
    className,
    promoEvents
  } = props;

  return (
    <LegacyPageSection className={className}>
      <BannerContent
        copy={copy}
        variant={variant}
        button={button}
        ctaButton={ctaButton}
        secondaryButton={secondaryButton}
        form={form}
        backgroundColor={backgroundColor}
        desktopBgImage={desktopBgImage}
        mobileBgImage={mobileBgImage}
        backgroundVariant={backgroundVariant}
        isLazy={isLazy}
        boxShadow={boxShadow}
        thankYouCopy={thankYouCopy}
        thankYouMobileImage={thankYouMobileImage}
        smallBoxShadow={smallBoxShadow}
        successContent={successContent}
        desktopOverflowImage={desktopOverflowImage}
        mobileOverflowImage={mobileOverflowImage}
        imageOverflowType={imageOverflowType}
        onlyCompanyEmail={onlyCompanyEmail}
        richTextProgressiveForm={richTextProgressiveForm}
        questions={questions}
        formSource={formSource}
        predefinedForm={predefinedForm}
        promoEvents={promoEvents}
      />
    </LegacyPageSection>
  );
};

Banner.displayName = 'Banner';

export default Banner;
