import React from 'react';
import Link from 'adapter/next/link';
import { CaretDown } from 'phosphor-react';
import { Box, Text, cn } from '@superside/ui';
import { useMenu } from '../utils/useMenu';

interface FooterDropDownColumnProps {
  items: Array<{ href: string; linkText: string }>;
  title: string;
}

export const FooterDropDownColumn: React.FC<FooterDropDownColumnProps> = (props) => {
  const { items, title = 'Menu' } = props;

  const { isOpen, toggleMenu } = useMenu();

  return (
    <Box
      className={cn(
        '768:max-w-[calc((100%-112px)/3)] <768:after:bg-grey-300 <768:after:h-px <768:after:w-full <768:last-of-type:after:h-0',
        isOpen && 'after:bg-transparent'
      )}
    >
      <Box className='768:gap-6 gap-3'>
        <button
          onClick={toggleMenu}
          aria-expanded={isOpen}
          aria-controls={title}
          id={`accordion-${title}`}
          className='768:cursor-text 768:py-0 flex w-full cursor-pointer flex-row items-center justify-between py-3 [&_div_span:first]:flex [&_div_span:first]:justify-between'
        >
          <span className='font-semibold'>{title}</span>
          <CaretDown
            className={cn('768:hidden 768:mr-4 mr-2 transition-transform', isOpen && 'rotate-180')}
          />
        </button>

        <Box
          id={title}
          aria-labelledby={`accordion-${title}`}
          className={cn(
            '768:gap-3 768:flex hidden gap-4',
            isOpen && '<768:animate-slide-down-appearing <768:flex'
          )}
        >
          {items.map((item) => (
            <Link key={item.href} href={item.href} className='<768:last:mb-3'>
              <Text underline='animated'>{item.linkText}</Text>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
