import React from 'react';
import { cn } from '@superside/ui';
import {
  PageSection as BasePageSection,
  type PageSectionProps as BasePageSectionProps
} from '@konsus/superside-kit';
import styles from './index.module.css';

export interface LegacyPageSectionProps extends BasePageSectionProps {
  isLight?: boolean;
}

export const LegacyPageSection: React.FunctionComponent<LegacyPageSectionProps> = (props) => {
  const { isLight = false, ...rest } = props;

  return (
    <BasePageSection
      background={isLight ? 'transparent-on-light' : 'transparent-on-dark'}
      paddingBottom
      paddingTop
      {...rest}
      className={cn(isLight && styles.lightContext, rest.className, 'page-section')}
      title={undefined}
    />
  );
};

export const lightContext = styles.lightContext;
