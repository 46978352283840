/* eslint-disable camelcase */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'adapter/next/router';
import { CaretRight, Spinner } from 'phosphor-react';
import GlobalContext from '@konsus/lego/src/contexts/GlobalContext';
import { getHubspotCommonFields } from '@konsus/lego/src/blocks/BookCall/hubspotFields';
import { prepareHubspotFormData, useEventId } from '@konsus/lego/src/utils/form';
import {
  useAnalytics,
  baseTrackingFieldsGenerator,
  FORM_TRACKING_PROVIDERS,
  TRACKING_EVENT_NAMES
} from '@konsus/analytics';
import { hubSpotApiPoint, portalId } from '@konsus/lego/src/blocks/BookCall/utils';
import { Box, Button, cn } from '@superside/ui';
import { VALID_EMAIL_REGEX, FORM_ERRORS, FREE_EMAIL_SERVICES } from '../utils/formValidation';

const isSSR = typeof window === 'undefined';

export const BookCallCustomForm: React.FC<{
  withSubscribe: boolean;
  redirectLink?: string;
  onlyCompanyEmail?: boolean;
  eventText?: string;
  formId?: string;
  onSubmit?: (form: any) => void;
  trackingEvents?: {
    form: string;
    form_type: string;
    item: string;
  };
}> = ({
  redirectLink = '/get-started',
  onlyCompanyEmail = false,
  formId = '7745a6c2-4671-4146-ab72-01525b0de032',
  onSubmit,
  trackingEvents,
  withSubscribe
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { utm, gclid } = useContext(GlobalContext) || {};
  const history = useRouter();

  const { track: baseTrack } = useAnalytics();
  const eventId = useEventId();

  useEffect(() => {
    setTimeout(() => {
      history.prefetch(redirectLink);
    }, 500);
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{ email: string }>();

  const redirect = useCallback(
    (form: { email: string }) => {
      const email = encodeURIComponent(form.email);

      const finalRedirectLink = redirectLink.includes('calendly')
        ? `${redirectLink}${redirectLink.includes('?') ? '&' : '?'}email=${email}`
        : `${redirectLink}?email=${email}`;

      if (!isSSR) {
        if (finalRedirectLink.includes('https://www.superside.com')) {
          const path = finalRedirectLink.split('https://www.superside.com');

          if (path.length > 1) {
            history.push(path[1]);

            return;
          } else {
            location.assign(finalRedirectLink);

            return;
          }
        }

        if (!finalRedirectLink.includes('http')) {
          history.push(finalRedirectLink);

          return;
        }

        location.assign(finalRedirectLink);
      }
    },
    [history, redirectLink]
  );

  const hubSpotHandle = useCallback(
    (form: { email: string; blog_subscription?: boolean }) => {
      setSubmitting(true);

      const { utm_source, utm_medium, utm_campaign } = utm || {};

      let fields = getHubspotCommonFields({ form, utm_campaign, utm_source, utm_medium, gclid });

      fields = fields.filter((field) => field.value);

      const baseTrackingFields = baseTrackingFieldsGenerator(
        eventId,
        formId,
        trackingEvents,
        history?.asPath
      );

      const data = prepareHubspotFormData(fields);

      const track = (eventName: string, extra = {}) =>
        baseTrack(eventName, {
          ...baseTrackingFields,
          email: form.email,
          ...extra,
          provider: FORM_TRACKING_PROVIDERS.INTERNAL_INLINE
        });

      return fetch(`${hubSpotApiPoint}/${portalId}/${formId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(() => {
          form.blog_subscription = withSubscribe;
          onSubmit ? onSubmit(form) : track(TRACKING_EVENT_NAMES.FORM_SUBMISSION);
          redirect(form);
        })
        .catch((err) => {
          !onSubmit && track(TRACKING_EVENT_NAMES.FORM_SUBMISSION_FAILURE, { error: err.message });
          redirect(form);
        });
    },
    [
      withSubscribe,
      utm,
      gclid,
      eventId,
      formId,
      trackingEvents,
      baseTrack,
      onSubmit,
      redirect,
      history
    ]
  );

  const emailField = useMemo(() => {
    return register('email', {
      validate: {
        required: (v: any) => !!v || FORM_ERRORS.REQUIRED,
        onlyCompanyEmail: onlyCompanyEmail
          ? (v: any) =>
              !FREE_EMAIL_SERVICES.includes(v.split('@')[1]) || FORM_ERRORS.INVALID_WORK_EMAIL
          : () => true,
        validEmail: (v: any) => VALID_EMAIL_REGEX.test(v) || FORM_ERRORS.INVALID_EMAIL
      }
    });
  }, [onlyCompanyEmail, register]);

  return (
    <form onSubmit={handleSubmit(hubSpotHandle)}>
      <Box className='relative max-w-[520px] flex-row'>
        <Box className='w-full items-stretch'>
          <Box className='bg-transparent'>
            <input
              placeholder='What’s your email address?'
              {...emailField}
              className={cn(
                'w-full rounded border py-5 pl-4 pr-16 text-base font-semibold transition-all duration-300',
                errors.email
                  ? 'border-coral-500 hover:border-coral-500 focus:outline-coral-500'
                  : 'border-white-200 hover:border-black-500 focus:outline-black-500'
              )}
            />
          </Box>
        </Box>

        <Button
          className='absolute right-2 top-2 flex h-12 w-12 items-center justify-center p-0'
          variant='isolated'
          size='lg'
          type='submit'
          disabled={submitting}
        >
          {submitting ? (
            <Spinner size={20} weight='bold' className='animate-spin' />
          ) : (
            <CaretRight size={20} weight='bold' />
          )}
        </Button>
      </Box>
      {errors.email?.message ? (
        <span className='text-coral-500 mt-4 block text-sm'>{errors.email?.message}</span>
      ) : null}
    </form>
  );
};

export default BookCallCustomForm;
