import React from 'react';
import { cn } from '@superside/ui';
import { RelativeBox } from '../Box';
import { styling } from '../BreakpointShowBox/breakpointShowStyles';

export const PageSectionContent = (props) => (
  <RelativeBox
    className={cn(
      props.className,
      'w-full',
      props.unlimited || props.maxWidth ? '' : ['1201:max-w-[1384px]', styling(props)],
      props.margin ? '' : 'mx-auto',
      props.maxWidth ? 'max-w-[var(--max-width-var-204a)]' : ''
    )}
    style={{
      '--max-width-var-204a': props.maxWidth ? props.maxWidth : 'initial'
    }}
    {...props}
  />
);

export const pageSectionVerticalMarginSize = 'xxxlarge';
export const pageSectionVerticalPaddingSize = 'xxxlarge';
export const pageSectionHorizontalPaddingSize = 'medium';
export const pageSectionHeadingMarginBottomSize = 'xlarge';
