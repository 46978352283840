import React from 'react';
import Link from 'adapter/next/link';
import dynamic from 'adapter/next/dynamic';
import Image from 'adapter/next/image';
import Banner from '@konsus/lego/src/blocks/Banner';
import { type TrackingFormData } from '@konsus/lego/src/utils/useSubscribeForm';
import { Box, cn, Logo, Text } from '@superside/ui';
import { PageSection } from '@konsus/lego/src/components';
import imagesGreenPlanetSvg from './images/green-planet.svg';
import { BOTTOM_LINKS, COPYRIGHT_TEXT, DEFAULT_HEADING, DEFAULT_TEXT } from './defaults';
import FooterForm from './FooterForm';
import { FooterDropDownColumn } from './FooterDropDownColumn';
import { ArticleFooterSection, type ArticleFooterSectionProps } from './ArticleFooterSection';

interface NavSettings {
  header: 'fullHeader' | 'onlyLogo' | 'withoutHeader';
  footer: 'fullFooter' | 'minimalisticFooter' | 'withoutFooter';
}

const SocialLinks = dynamic(
  () => import(/* webpackchunkName: "shared" */ '@konsus/lego/src/blocks/SocialLinks')
);

export type FooterBannerProps = {
  superscriptText?: string;
  headerText?: string;
  subHeaderText?: string;
  subHeaderRichText?: { content: Array<any> };
  showPlanet?: boolean;
};

type FooterProps = {
  showFullFooter?: boolean;
  tinyFooter?: boolean;
  navSettings: NavSettings;
  isNewWebsite: boolean;
  footerSection?: ArticleFooterSectionProps;
} & FullFooterProps;

type LinksArrayType = Array<{ href: string; linkText: string }>;

type FullFooterProps = {
  formId?: string;
  mainLinks: LinksArrayType;
  showFooterBanner?: boolean;
  banner?: FooterBannerProps;
  withCapabilities?: boolean;
  withLearn?: boolean;
  withNewsletterForm?: boolean;
  trackingEvents?: TrackingFormData;
  website?: 'superside' | 'careers';
  footerCopy?: {
    heading?: string;
    text?: string;
  };
  capabilitiesLinks: LinksArrayType;
  learningCenterLinks: LinksArrayType;
  bottomLinks?: LinksArrayType;
};

export const Footer: React.FC<FooterProps> = (props) => {
  const { tinyFooter, showFullFooter, navSettings, isNewWebsite, footerSection, ...rest } = props;

  if (
    (!isNewWebsite && !showFullFooter && !tinyFooter) ||
    navSettings?.footer === 'withoutFooter'
  ) {
    return null;
  }

  if ((!isNewWebsite && tinyFooter) || navSettings?.footer === 'minimalisticFooter') {
    return <MinimalFooter />;
  }

  return (
    <>
      {footerSection && !footerSection.disable ? (
        <Box className='bg-bor-dark-strong -mt-px overflow-hidden'>
          <ArticleFooterSection {...footerSection} />
          <FullFooter {...rest} />
        </Box>
      ) : (
        <FullFooter {...rest} />
      )}
    </>
  );
};

export default Footer;

const MinimalFooter = () => (
  <PageSection>
    <Box className='text-light 768:flex-row-reverse mb-14 mt-24 items-center justify-between'>
      <SocialLinks size='small' direction='row' gap='small' background='#0F2133' />

      <Box className='<768:mt-6'>
        <span className='text-bor-foreground text-sm'>{COPYRIGHT_TEXT}</span>
      </Box>
    </Box>
  </PageSection>
);

const FullFooter = ({
  showFooterBanner,
  banner,
  website,
  mainLinks,
  withCapabilities,
  withLearn,
  withNewsletterForm,
  capabilitiesLinks,
  learningCenterLinks,
  bottomLinks = BOTTOM_LINKS,
  formId = '5c3579e9-7453-4329-ad87-9c7c57a06d25',
  trackingEvents,
  footerCopy
}: FullFooterProps) => {
  const isSuperside = website === 'superside';
  const { heading = DEFAULT_HEADING, text = DEFAULT_TEXT } = footerCopy || {};

  return (
    <>
      {showFooterBanner && banner?.showPlanet ? (
        <Box className='relative mx-auto max-w-[1336px]'>
          <Image
            className='1280:block 1650:right-[calc(100%-110px)] 1650:-top-[141px] absolute -top-[215px] right-[calc(100%-320px)] hidden'
            src={imagesGreenPlanetSvg}
            width={752}
            height={445}
            alt=''
          />
        </Box>
      ) : null}
      <PageSection
        isLight
        unlimited
        paddingBottom='none'
        className={cn(
          'shadow-dark/20 768:max-w-[calc(100%-48px)] 768:mt-16 768:mb-8 768:pt-20 1440:max-w-[calc(100%-64px)] <768:mb-0 <768:rounded-b-none 768:px-6 z-10 mx-auto mb-4 mt-8 rounded-lg px-4 shadow-2xl',
          showFooterBanner && '1024:mt-48 1024:mb-8 mb-4 mt-64'
        )}
      >
        {showFooterBanner ? <FooterBanner {...banner} /> : null}

        <Box className='768:mb-12 1280:mb-20 mb-9 h-full justify-end self-start'>
          <Link href='/'>
            <Logo className='text-bor-dark 1280:h-14 h-10 min-h-full w-[155px]' />
          </Link>
        </Box>

        <Box className='768:flex-row 768:flex-wrap 768:gap-14 1280:justify-between 1280:border-b 1280:border-b-grey-200 1280:pb-20 1280:flex-nowrap'>
          {isSuperside ? (
            <Box className='768:flex-row 768:flex-wrap 768:gap-14 1280:justify-between 1280:pt-1 1440:gap-24 1440:pt-2'>
              {mainLinks ? <FooterDropDownColumn items={mainLinks} title='Superside' /> : null}

              {withCapabilities ? (
                <FooterDropDownColumn items={capabilitiesLinks} title='Services' />
              ) : null}

              {withLearn ? (
                <FooterDropDownColumn items={learningCenterLinks} title='Learn' />
              ) : null}
            </Box>
          ) : mainLinks ? (
            <Box className='768:flex-row 768:py-0 768:gap-12 gap-5 py-6'>
              {mainLinks.map((item) => (
                <Link key={item.href} href={item.href} className='text-dark text-xl font-semibold'>
                  <Text underline='animated'>{item.linkText}</Text>
                </Link>
              ))}
            </Box>
          ) : null}

          {withNewsletterForm ? (
            <FooterForm formId={formId} trackingEvents={trackingEvents} />
          ) : null}
        </Box>
        <Box className='1280:pt-10 1280:mb-10 mb-8 pt-6'>
          <span className='768:text-base mb-3 text-sm font-semibold'>{heading}</span>
          <span className='768:text-base text-sm'>{text}</span>
        </Box>
        <Box className='768:mb-12 1440:items-center flex-row flex-wrap items-start justify-between pb-8'>
          <Box className='768:w-1/2 1280:w-auto 1280:mb-0 mb-8 flex-row flex-wrap items-center'>
            <Box className='1280:w-auto 1280:mb-0 1280:mr-8 mb-3 w-full'>
              <span className='text-sm'>{COPYRIGHT_TEXT}</span>
            </Box>
            <BottomLinks bottomLinks={bottomLinks} />
          </Box>

          <Box className='768:w-full 768:items-start 1280:w-auto'>
            <SocialLinks
              size='small'
              direction='row'
              gap='4'
              background='#0F2133'
              website={website}
            />
          </Box>
        </Box>
      </PageSection>
    </>
  );
};

const FooterBanner = ({
  superscriptText,
  headerText = '',
  subHeaderText,
  ...rest
}: FooterBannerProps) => (
  <Box className='mb-15 768:relative 768:mb-18 1280:mb-24 -mt-64'>
    {/* @ts-ignore @TODO fix Banner types to require form only on variants that required  */}
    <Banner copy={{ superscriptText, headerText, subHeaderText }} {...rest} />
  </Box>
);

interface BottomLinksProps extends Pick<FullFooterProps, 'bottomLinks'> {}

const BottomLinks: React.FC<BottomLinksProps> = ({ bottomLinks }) => (
  <Box className='1280:gap-8 flex-row flex-wrap gap-4'>
    {bottomLinks?.map((link) => {
      return (
        <Box key={link.href}>
          <a
            href={link.href}
            className='text-dark hover:text-darker text-sm underline hover:no-underline'
          >
            {link.linkText}
          </a>
        </Box>
      );
    })}
  </Box>
);
